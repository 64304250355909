/**
 * Init js selector
 *
 * @param mode [async, synch]
 */
function initCustomSelect(mode = 'async'){
    var selects = document.querySelectorAll('.my-select-wrapper');
    if (selects !== null) {
        for(const select of selects) {
            select.addEventListener('click', function (e) {
                if (e.target.classList.contains('my-select__trigger') ||
                    e.target.closest('.my-select__trigger') !== null) {
                    e.stopImmediatePropagation();
                    current_selector = this.querySelector('.my-select');
                    if(current_selector.classList.contains('open')){
                        this.querySelector('.my-select').classList.remove('open');
                    } else {
                        this.querySelector('.my-select').classList.add('open');
                    }
                }
                //this.querySelector('.my-select').classList.add('open');
            }, true) // true to prevent conflict with option click
            for (const option of document.querySelectorAll(".custom-option")) {
                option.addEventListener('click', function (e) {
                    if (!this.classList.contains('selected')) {
                        document.querySelectorAll('.my-select').forEach(function(element){
                            element.classList.remove('open');
                        });
                        if(mode === "async") {
                            var selected = this.parentNode.querySelector('.custom-option.selected');
                            if(selected !== null) {
                                selected.classList.remove('selected');
                            }
                            this.classList.add('selected');
                            this.closest('.my-select').querySelector('.my-select__trigger span').innerHTML = this.innerHTML;
                        }
                        // Sync value for all selects in a page.
                        if(mode === "sync") {
                            var value = this.getAttribute('data-value');
                            var all_options = document.querySelectorAll('.my-select-wrapper .custom-options .custom-option');
                            for (const option of all_options){
                                option.classList.remove('selected');
                                if(option.getAttribute('data-value') === value){
                                    option.classList.add('selected');
                                    option.closest('.my-select').querySelector('.my-select__trigger span').innerHTML = this.innerHTML;
                                }
                            }
                        }
                    } else {
                        this.classList.remove('selected');
                    }
                })
            }
            window.addEventListener('click', function (e) {
                const select = document.querySelector('.my-select');
                if (!select.contains(e.target)) {
                    select.classList.remove('open');
                }
            })
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    initCustomSelect();
});